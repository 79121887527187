import {mapActions, mapGetters, mapMutations} from "vuex";

import mainButton from '@/components/main-button/index.vue';
import mainSelect from '@/components/main-select/index.vue';
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
//sections
export default {
    name: "salon",
    mixins: [validationMixin],
    components: {
        mainButton,
        mainSelect

    },
    data() {
        return {
            status: false,
            payload: {
                name: '',
                phone: '',
                email: '',
                message: '',
                web_page: '',
                salon_title: '',
                street: '',
                city: '',
                country: '',
                post_code: '',
                square_meters: '',
                brands: ''
            },
            validationErrors: {},
            vueTel: {
                phone: '',
                props: {
                    maxLen: 11,
                    validCharactersOnly: true,
                    mode: "international",
                    dynamicPlaceholder: 'true',
                    disabledFetchingCountry: true,
                    disabled: false,
                    required: true,
                    enabledCountryCode: false,
                    enabledFlags: true,
                    onlyCountries: [],
                    ignoredCountries: [],
                    autocomplete: "off",
                    name: "telephone",
                    inputClasses: "",
                    inputOptions: {
                        showDialCode: true,
                    },
                    disabledFormatting: true,
                    wrapperClasses: "input-row"
                }
            },
        }
    },
    validations: {
        payload: {
            name: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(2),
            },
            message: {
                required,
                maxLength: maxLength(191),
            },
            phone: {
                required,
                maxLength: maxLength(18),
                minLength: minLength(7),
            },
            email: {
                email,
                maxLength: maxLength(30),
                required,
            },
            brands: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(1),
            },
        },
    },
    created() {
        this.getCountries()
    },
    computed: {
        ...mapGetters({
            countries: 'contact/countries',
            loading: 'contact/salonLoading'
        }),
        nameError() {
            const error = []
            if (!this.$v.payload.name.$dirty) {
                return error
            }
            if (!this.$v.payload.name.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.name.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.name.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.name) {
                this.validationErrors.name.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        messageError() {
            const error = []
            if (!this.$v.payload.message.$dirty) {
                return error
            }
            if (!this.$v.payload.message.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.message.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (this.validationErrors.message) {
                this.validationErrors.message.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        phoneError() {
            const error = []
            if (!this.$v.payload.phone.$dirty) {
                return error
            }
            if (!this.$v.payload.phone.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.phone.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 15))
            }
            if (!this.$v.payload.phone.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 7))
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        emailError() {
            const error = []
            if (!this.$v.payload.email.$dirty) {
                return error
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('errorEmail'))
            }
            if (!this.$v.payload.email.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 30))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        brandsError() {
            const error = []
            if (!this.$v.payload.brands.$dirty) {
                return error
            }
            if (!this.$v.payload.brands.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.brands.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.brands.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 8))
            }
            if (this.validationErrors.brands) {
                this.validationErrors.brands.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
    },

    methods: {
        ...mapActions({
            getCountries: 'contact/GET_CONTACT_COUNTRY',
            sendSalonData: 'contact/SET_SALON_DATA'
        }),
        ...mapMutations({}),
        submitForm() {
            this.$v.$touch();
            if (!this.$v.payload.$invalid) {

                let obj = {}
                obj.country = this.payload.country.title
                let data = Object.assign(this.payload, obj)

                this.sendSalonData(data).then(() => {
                    this.$toasted.success(this.$t('contact.salonSuccessMessage'));
                    this.$v.$reset();
                    this.payload = {
                        name: '',
                        phone: '',
                        email: '',
                        message: '',
                        web_page: '',
                        salon_title: '',
                        street: '',
                        city: '',
                        country: '',
                        post_code: '',
                        square_meters: '',
                        brands: ''
                    }
                }).catch(error => {
                    if (error.status === 422) {
                        this.$toasted.error(this.$t('contact.salonErrorMessage'));
                        const errors = error.data.errors;
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e);
                            })
                        }
                    }
                })
            }
        }
    }
}
